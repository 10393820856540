import { container, title, backgroundColor } from 'assets/jss/material-kit-react.jsx'

const landingPageStyle = {
  backgroundColor: {
    ...backgroundColor,
  },
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {

    position: 'relative',
    zIndex: '3',
  },
  centered: {
    justifyContent: 'center',
    textAlign: 'center',
  },
 }

export default landingPageStyle
