import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage.jsx'

import { graphql, useStaticQuery } from 'gatsby'
import PageHeader from '../../components/PageHeader/PageHeader'
import Section from '../../components/Section/Section'
import Content from '../../elements/Content/Content'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import ButtonOutlinedOptOut from '../../components/ButtonOutlinedOptOut/ButtonOutlinedOptOut'


const Datenschutzerklaerung = props => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsDatenschutzerklaerung{
        slug
        titel
        untertitel
        hintergrundbild{
          alt
          fluid(maxWidth:1500){
            src
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        content{
          __typename
          ...on DatoCmsH2{
            heading
          }
          ...on DatoCmsH3{
            heading
          }
          ...on DatoCmsH4{
            heading
          }
          ...on DatoCmsH5{
            heading
          }
          ...on DatoCmsH6{
            heading
          }
          ...on DatoCmsText{
            text
          }
          ...on DatoCmsImage{
            image{
              alt
              fluid(maxWidth: 705) {
                src
                ...GatsbyDatoCmsFluid_noBase64
              }}
          }

        }
      }
      datoCmsHome{
        spainholidayBanner{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
  `)

  return (
    <div>
      <Header
        SEOtitle={data.datoCmsDatenschutzerklaerung.titel}
        SEOdescription={data.datoCmsDatenschutzerklaerung.untertitel}
        SEOimage={data.datoCmsDatenschutzerklaerung.hintergrundbild.fluid.src}
        SEOpathname={data.datoCmsDatenschutzerklaerung.slug}
      />
      <PageHeader
        image={data.datoCmsDatenschutzerklaerung.hintergrundbild.fluid}
        title={data.datoCmsDatenschutzerklaerung.titel}
        subtitle={data.datoCmsDatenschutzerklaerung.untertitel}
        alt={data.datoCmsDatenschutzerklaerung.hintergrundbild.alt}
      />


      <Section innerStyles={{ maxWidth: 705 }} paddingTop={20}>
        <Content
          content={data.datoCmsDatenschutzerklaerung.content}
        />
        <ButtonOutlinedOptOut/>
      </Section>
      <BlogSection/>
      <InstagramSection/>
      <SpainHolidaysCta
        title={data.datoCmsHome.spainholidayBanner[0].title}
        subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
      />
      <Footer/>
    </div>
  )
}

export default withStyles(landingPageStyle)(Datenschutzerklaerung)
