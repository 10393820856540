import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import buttonOutlinedOptOutStyle from './buttonOutlinedOptOutStyle'


const ButtonOutlinedOptOut = props => {

  const { classes } = props

  return (

      <a href="javascript:gaOptout();">
        <div className={classes.wrapper}>
          Cookies deaktivieren

        </div>
      </a>

  )

}

export default withStyles(buttonOutlinedOptOutStyle)(ButtonOutlinedOptOut)
